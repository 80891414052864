import { Typography, Divider} from 'antd';
import styled, {createGlobalStyle} from 'styled-components';

const { Text } = Typography;

export const CarouselGlobalStyle = createGlobalStyle`

  .ant-carousel .slick-dots {
    position: relative !important;
    top: 30px;
  }
  .ant-carousel .slick-dots li {
    /* border: 1px solid #3F3F3F; */
    border-style: solid;
    border-color: #3F3F3F;
    /* border-size: 1px; */
    height:24px !important;
    width: 24px !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .ant-carousel .slick-dots button {
    height: 18px !important;
    width: 18px !important;
    border-radius: 50%;
    background: transparent;
    border-color: #3F3F3F;
  }
  
  .ant-carousel .slick-dots li.slick-active {
    height: 24px !important;
    width: 24px !important;
    background: #3f3f3f;

  }
  .ant-carousel .slick-dots .slick-active button {
    background: #3F3F3F !important;
  }
`;

export const CliTitle = styled.h4`
    /* H4-32px */
  font-style: normal;
  font-weight: 600;
  font-size: calc(42px + 0.1vw);
  line-height: calc(57px + 0.2vw);
  /* identical to box height, or 58px */

  letter-spacing: -0.05em;
  @media only screen and (max-width: 681px) {
    font-weight: bold;
    font-size: 32px;
    line-height: 110%;
    /* or 35px */

    text-align: center;
    letter-spacing: -0.05em;

  }
`;

export const CarouselImage = styled.img`
  width: 100%;
  height: intrinsic;
    background: '#364d79',
`;

export const TitleStyled = styled.h3`
  font-weight: bold;
  font-size: calc(28px + 0.2vw);
  line-height: calc(36px + 0.2vw);
  text-align: center;

  margin-top: 5rem;
  @media only screen and (max-width: 681px) {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    display: flex;
  }
`;

export const TextStyled = styled(Text)`
font-size: calc(14px + 0.2vw);
font-weight: normal;
line-height: calc(22px + 0.2vw);
display: flex;
align-items: center;
text-align: center;

/* Black */
color: #3F3F3F;
margin-top: 50px;
  @media only screen and (max-width: 681px) {
    font-size: 14px;
    line-height: 142%;
    /* or 20px */
    letter-spacing: -0.02em;
    padding: 0 34px;
  }
`;

export const PricingTitle = styled.h2`
  font-weight: 600;
  font-size: calc(32px + 0.2vw);
  line-height: calc(120% + 0.2vw);
  /* or 43px */

  letter-spacing: -0.05em;

  text-align: center;
  margin-top: 124px;
  margin-bottom: 50px;
  @media only screen and (max-width: 681px) {
    margin-top: 100px;
    margin-bottom: 24px;
    font-weight: bold;
    font-size: 32px;
    line-height: 110%;
    /* or 35px */
  }
`;

export const Card = styled.div`
  background: #fff3e9;
  border-radius: 0px 4px 0px 0px;
  width: calc(250px + 0.1vw) !important;
  max-width: calc(275px + 0.1vw) !important;
  min-width: calc(250px) !important;
  height: calc(350px + 0.2vh);
  /* display: flex;
  justify-content: center;
  align-items: center; */
  margin: 0 auto;
  @media only screen and (max-width: 681px) {
    width: 250px;
    height: 350px;
  }
`;

export const CardHeader = styled.div`
  background-color: inherit;
  margin-top: 45px;
`;

export const CardTitle = styled.h4`
  font-weight: 600;
  line-height: calc(120% + 0.2vw);
  /* identical to box height, or 29px */
  /* Neutrals/600 */

  color: #212429;
    @media only screen and (max-width: 681px) {
      font-size: 24px;
    }
`;

export const CardSubTitle = styled.p`
  text-align: center;
  /* Neutrals/500 */
  color: #495057;
  margin-top: 0.5rem;
  line-height: calc(142% + 0.2vw);
  /* identical to box height, or 20px */

  letter-spacing: -0.02em;

  /* Neutrals/500 */

  color: #495057;
@media only screen and (max-width: 681px) {
  font-size: 14px;
}
`;

export const Price = styled.h4`
font-weight: 600;
font-size: calc(20px + 0.1vw);
line-height: 120%;
/* identical to box height, or 29px */

/* Neutrals/600 */

color: #212429;
@media only screen and (max-width: 681px) {
  font-size: 24px;
}
`;

export const CardBody = styled.div`
  width: 100%;
  margin-bottom: 25px;
  padding: 16px;
`;

export const DividerStyled = styled(Divider)`

width: calc(215px + 0.2vw); 
margin: 0 auto;
/* Gray 4 */
border: 1px solid #BDBDBD;
min-width: unset;
@media only screen and (max-width: 681px) {
  width: calc(173px + 0.2vw); 
}
`
export const Link = styled.a`
  display: none
`