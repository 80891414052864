/* eslint-disable object-curly-newline */
/* eslint-disable import/no-unresolved */
/* eslint-disable quotes */
import React from 'react';
import { Row, Col } from 'antd';
import BannerHeader from './BannerHeader';
import ProviderContainer from './ProviderContainer';
import {
  GradientRow,
  BannerGlobalStyle,
  DashboardRow,
  DashboardImage,
  DashboardCol,
} from './styles';
import dashboard from '../../images/dashboard-view.svg';

// eslint-disable-next-line react/prop-types
function Banner({ setModalVisible }) {
  return (
    <>
      <BannerGlobalStyle />
      <GradientRow
        id="home"
        className="mb-6 d-flex flex-md-column justify-content-center align-items-md-center"
      >
        <Col xs={20} md={14}>
          <BannerHeader setModalVisible={setModalVisible} />
        </Col>
        <DashboardCol xs={20} className="mb-5 d-flex justify-content-center">
          <DashboardImage src={dashboard} alt="rundis dashboard view" fluid />
        </DashboardCol>
      </GradientRow>
      <Row className="d-flex justify-content-center mt-5 pt-5">
        <Col>
          <ProviderContainer />
        </Col>
      </Row>
    </>
  );
}

export default Banner;
