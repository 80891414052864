import styled, { createGlobalStyle } from 'styled-components';
import { Button } from 'antd';

export const HeaderGlobalStyle = createGlobalStyle`
  #sign-up-btn-nav-link {
    color: #fff !important;
  }
  #sign-up-btn-nav-link:focus, #sign-up-btn-nav-link:hover, #sign-up-btn-nav-link:active {
    border-color: unset;
  }
  .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: unset;
  }
 .gray-font .nav-link {
   color: #3F3F3F !important;
   font-size: calc(10px+0.1vw);
 }

 .show {
   min-height:93vh;
   display: flex;
   padding: 50px 40px;
   flex-direction: column;
   justify-content: space-between;
    @media only screen and (max-width: 681px) {
      padding: 50px 40px 17vh 40px;
    }
 }

   .nav-item {
     margin-top: 2vh;
   }

   .spa-links {
      @media only screen and (max-width: 681px) {
        width: 100%;
      }
   }
   .spa-links * {
     font-size: 14px;
   }
   .login-button-link {
     margin-top: 2vh;
     padding: 0px 0px;
   }
 
`;

export const Navbar = styled.nav`
  position: fixed;
  top: 0;
  width: 100vw;
  height: inherit !important;
  z-index: 2100;
  padding-left: 120px;
  padding-right: 120px;
  @media only screen and (max-width: 681px) {
    padding-left: 0px;
    padding-right: 0px;
  } 
`;
export const Brand = styled.a`
  display: flex;
  align-items: center;
  width: 110px;
  .logo {
    width: 80px;
    margin: 0px 0px;
  }
@media only screen and (max-width: 681px) {
padding-left: 35px;
}
`;
export const Collapse = styled.div`
  z-index: 2201;
  @media only screen and (max-width: 681px) {
  width: 100vw;
  height: 80vh;
  }
  `;
export const Nav = styled.ul`
  z-index:2200;
 `;

export const NavItem = styled.li`
z-index: 2202;
font-size: 14px;
`;
export const Link = styled.a`
font-size: calc(14px+0.1vw);
&:hover, &:focus {
  background-color: unset;
  color: #70009c !important;
}

`;
export const Toggle = styled.button`
    border: none;
    padding-right: 35px;
    .close {
    font-size: 32px;
    font-weight: 300;
    color: #24292E;
  }
`;

export const LoginButton = styled(Button)`
  margin: 0px;
  border: none;
  width: 100%;
  text-align: center;
  background-color: fff;
  font-size: 14px;

  &:hover, &:focus {
    background-color: unset;
    color: #70009c;
  }
    @media only screen and (max-width: 681px) {
      padding-bottom: 40px;
    }
`;

export const SignUpButton = styled(Button)`
  width: 140px;
  height: 48px;
  border-radius: 8px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

   &:hover, &:focus {
     color: #fff;
   }
`;
