/* eslint-disable quotes */
import React from 'react';
// eslint-disable-next-line object-curly-newline
import { Row, Col, Carousel } from 'antd';
// import AwesomeSlider from 'react-awesome-slider';
// import withAutoplay from 'react-awesome-slider/dist/autoplay';
// import 'react-awesome-slider/dist/styles.css';
import RundisCli from '../../images/rundis-cli.png';
import { CarouselImage, TitleStyled, TextStyled } from './styles';

// const AutoplaySlider = withAutoplay(AwesomeSlider);

const CliCarousel = () => {
  return (
    <>
      {/* <Row className="d-flex justify-content-center">
        <Col sm={20}> */}
      <Carousel
        autoplay
        autoplaySpeed={5000}
        dotsClass="rundis-dots"
        speed={1000}
      >
        <div>
          <Row className="d-flex justify-content-center">
            <Col sm={22} md={20}>
              <CarouselImage
                src={RundisCli}
                alt="First slide"
                className="fluid"
              />
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col md={12}>
              <TitleStyled
                className="text-center d-flex flex-column align-items-center"
                strong
                style={{ marginTop: '48px' }}
              >
                Flawless CLI Experience
              </TitleStyled>
              <TextStyled className="text-center">
                The product is language agnostic allowing for custom actions.
                Tool integrates the change does that for you. It works similar
                to what AWS lambda does that for you.
              </TextStyled>
            </Col>
          </Row>
        </div>
        <div>
          <Row className="d-flex justify-content-center">
            <Col sm={22} md={20}>
              <CarouselImage
                src={RundisCli}
                alt="Second slide"
                className="fluid"
              />
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col md={12}>
              <TitleStyled
                className="text-center d-flex flex-column align-items-center"
                strong
                style={{ marginTop: '48px' }}
              >
                Simple & Efficient CLI
              </TitleStyled>
              <TextStyled className="text-center">
                The product is language agnostic allowing for custom actions.
                Tool integrates the change does that for you. It works similar
                to what AWS lambda does that for you.
              </TextStyled>
            </Col>
          </Row>
        </div>
        <div>
          <Row className="d-flex justify-content-center">
            <Col sm={22} md={20} className="d-flex justify-content-center">
              <CarouselImage
                src={RundisCli}
                alt="Third slide"
                className="fluid"
              />
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col md={12}>
              <TitleStyled
                className="text-center d-flex flex-column align-items-center"
                strong
                style={{ marginTop: '48px' }}
              >
                Simple & Efficient CLI
              </TitleStyled>
              <TextStyled className="text-center">
                The product is language agnostic allowing for custom actions.
                Tool integrates the change does that for you. It works similar
                to what AWS lambda does that for you.
              </TextStyled>
            </Col>
          </Row>
        </div>
      </Carousel>
      {/* </Col>
      </Row> */}
    </>
  );
};

export default CliCarousel;
