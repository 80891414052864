import { Card, Typography } from 'antd';
import styled from 'styled-components';

const { Meta } = Card;
const { Title } = Typography;

export const ContainerStyled = styled.div`
  background: linear-gradient(
    359.68deg,
    rgb(255, 140, 46, 0.1),
    rgb(112, 0, 156, 0.1)
  );
  /* opacity: 0.1; */
  padding: 0px 140px 110px 140px;
  border-radius: 4px;

  @media only screen and (max-width: 681px) {
    padding: 2rem;
  }
`;

export const FeatureTitle = styled(Title)`
  font-style: normal;
  font-weight: 600;
  font-size: calc(48px + 0.1vw);
  line-height: calc(110% + 0.2vw);
  /* or 53px */
  /* Black */

  color: #3F3F3F;
  letter-spacing: calc(-0.05em + 0.1vw);

  margin-top: 117px;

    @media only screen and (max-width: 681px) {
      font-weight: bold;
      font-size: 32px;
      /* or 35px */

      text-align: center;
      letter-spacing: -0.05em;


    } 
`;

export const CardStyled = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 200px;
  width: calc(300px + 2vw);
  max-width: 450px;
  padding: 0px 45px 25px 0px;
  margin-bottom: 50px;
  background: inherit;
  border: none;
  height: 200px;

  .ant-card-body {
    padding: unset;
  }
    @media only screen and (max-width: 990px) {
      padding: unset;
      align-items: center;
      margin: 0px auto 75px auto;
    }

  @media only screen and (max-width: 681px) {
    height: auto;
    align-items: center;
    width: unset;
    margin-bottom: 0px;
    padding: 0px 0px 25px 0px;
  }
`;
export const MetaStyled = styled(Meta)`
  margin: 17px 0;
  .ant-card-cover {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .ant-card-meta-detail{
    font-weight: 600;
    /* margin: 1rem 0; */
    font-weight: normal;
    color: rgb(112, 0, 156);
  }

  .ant-card-meta-title {
    font-weight: bold;
    font-size: calc(22px + 0.1vw);
    line-height: calc(30px + 0.2vw);

    color: #000000;
    margin-bottom: 30px;
  }

  .ant-card-meta-detail .ant-card-meta-description {
    margin-top: 30px;
    font-size: calc(14px + 0.1vw);
    line-height: calc(22px + 0.2vw);
  }
  @media only screen and (max-width: 681px) {
    .ant-card-meta-detail .ant-card-meta-title {
      text-align: center;
      font-size: 20px;
      line-height: 24px;
    }
    .ant-card-meta-detail .ant-card-meta-description {
      padding-top: 24px;
      text-align: center;
      font-weight: normal;
      font-size: 14px;
      line-height: 142%;
      /* or 20px */
      margin-top: 0px;


      letter-spacing: -0.02em;
      /* Gray 1 */

      color: #333333;
      }
  }
`;
