import React from 'react';
import { Button, Typography } from 'antd';
import { BannerTitle, StyledHeader } from './styles';

const { Text } = Typography;
// eslint-disable-next-line react/prop-types
const BannerHeader = ({ setModalVisible }) => (
  <StyledHeader className="d-flex flex-column align-items-center justify-content-md-start text-white">
    <BannerTitle className="text-center text-white">
      Track & Automate
      <br />
      for Better Workflow
    </BannerTitle>
    <Text className="text-center easy-use-text text-white">
      Easy-to-Use Automation & Intelligence tool for Effective Team &
      Enterprise-Level Organization. Consumes Company Data and Automates
      Workflows.
    </Text>
    <Button
      type="primary"
      size="large"
      className="signup-free-btn"
      style={{
        width: '211px',
        height: '54px',
        borderRadius: '8px',
        zIndex: '999',
        borderColor: 'ButtonFace',
      }}
      onClick={() => setModalVisible(true)}
    >
      Sign Up for Free
    </Button>
  </StyledHeader>
);

export default BannerHeader;
