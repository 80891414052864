import React from 'react';
import { Typography } from 'antd';
import { ReactComponent as RundisIcon } from '../../images/Rundis.svg';
import { ModalNavbar, Brand } from './styles';

const { Title } = Typography;

const ModalTitle = () => (
  // eslint-disable-next-line react/jsx-wrap-multilines
  <nav className="navbar navbar-expand-lg">
    <Brand href="#home" className="navbar-brand">
      <Title className="logo bg-white d-flex align-items-baseline">
        <RundisIcon width="630px" />
      </Title>
    </Brand>
  </nav>
);

export default ModalTitle;
