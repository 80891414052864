/* eslint-disable no-unused-vars */
import { Col, Typography, Form, Row } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';

const { Text } = Typography;

export const ContactFormGlobalStyle = createGlobalStyle`
#contact-form .form-row {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.ant-form-item {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.ant-form-item-label label {
  color: #fff;
}
`;

export const FormContainer = styled.div`
  background: linear-gradient(
    327.15deg,
    #ff8c2e -1.6%,
    #70009c 59.76%,
    #70009c 74.57%
  );
  border-radius: 4px;
  min-height: 860px;
  color: #fff;
`;

export const TopRow = styled(Row)`
  display: flex;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 50px;
  color: #fff;
`;
export const TopText = styled(Text)`
  font-weight: 600;
  font-size: calc(12px + 0.1vw);
  line-height: calc(120% + 0.25vw);
  /* or 14px */
  letter-spacing: 0.105em;
  text-transform: uppercase;
  color: #fff;
  @media only screen and (max-width: 769px) {
    font-size: 12px;
    line-height: 120%;
  }
`;
export const FormTitle = styled.h3`
  /* H3 */
  font-weight: 600;
  font-size: calc(54px + 0.1vw);
  line-height: calc(120% + 0.25vw);
  /* identical to box height, or 58px */
  letter-spacing: -0.05em;
  color: #fff;
  @media only screen and (max-width: 769px) {
    font-size: 58px;
    line-height: 120%;
  }
`;
export const BottomText = styled(Text)`
  /* Paragraph */
  font-size: calc(12px + 0.1vw);
  line-height: calc(24px + 0.25vw);
  /* identical to box height, or 150% */
  letter-spacing: -0.02em;
  color: #fff;
  @media only screen and (max-width: 769px) {
    max-width: 80vw;
  }
`;

export const FormCol = styled(Col)`
  width: 100%;
  @media only screen and (max-width: 769px) {
    max-width: 90vw;
  }
`;

export const StyledForm = styled(Form)`
  color: #fff;

  .ant-form-item-label {
    text-align: left;
    color: #fff;
    font-weight: bold;
    font-size: calc(14px + 0.1vw);
    line-height: calc(19px + 0.25vw);
    /* identical to box height */
  }

  

  .ant-form-item-control-input-content {
    height: 56px;
    background: #ffffff;
    /* Mono/Gray 2 */
          @media only screen and (max-width: 769px) {
            height: 100%;
          }
  }
  .ant-form-item-control-input-content .ant-input {
    border: none;
    margin-top: 6px;
    /* margin-left: 8px; */
  }
  
  .note-textarea * .ant-form-item-control-input-content {
    height:auto;
  }

  .ant-form-item-control-input-content .ant-input::placeholder,
  .ant-select-selection-placeholder,
  .ant-select {
    font-weight: 500;
    font-size: calc(14px + 0.1vw);
    line-height: calc(20px + 0.25vw);
    /* identical to box height, or 125% */
    /* Gray 4 */
    color: #bdbdbd;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
    /* padding-top: 6px !important; */
    padding-left: 0px;
  }
  
  .ant-select-selector {
    height: fit-content !important;
  }

  .ant-input:focus,
  .ant-selector:focus,
  .ant-select {
    outline: none;
    background: #fff;
  }

  .ant-form-item-control-input-content .ant-input::placeholder,
  .ant-select-selection-placeholder {
    /* Firefox 18- */
    color: grey;
  }



  #size {
    /* padding: 6px; */
    width: 100%;
  }

  .button-group .ant-form-item-control-input-content,
  .ant-select .ant-select-selector,
  .ant-select-selection-search-input,
  .ant-select,
  .ant-select-selection-item {
    background: inherit;
    border: none;
    color: inherit !important;
    font-size: 16px !important;
    line-height: 20px !important;
    display: flex;
    justify-content: flex-start;
    height: 46px;
  }

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    padding: 6px 11px 0px 11px;
}

   .form-row .button-group  * {
      @media only screen and (max-width: 769px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
      } 
   }
   .left, .right {
     width: inherit; 
   }

   .left {
     padding-right:35px;
      @media only screen and (max-width: 769px) {
      padding: unset;
      }
    }
    .right {
      padding-left:35px;
      @media only screen and (max-width: 769px) {
      padding: unset;
      }
   }

  .button-group button {
    border-radius: 4px;
    /* border: #3f3f3f 1px solid; */
    border: none;
    margin-left: 1rem;

    font-weight: 600;
    font-size: calc(14px + 0.1vw);
    line-height: calc(22px + 0.1vw);
    /* identical to box height *
      /* Black */
    color: #fff;
    background-color: #F27243;
    height: 48px !important;
    width: 246px !important;
    @media only screen and (max-width: 769px) {
      margin-left: 0px;
    }
  }

  .button-group button:hover {
    background-color: #550076;
    border-color: none !important;
  }

  .button-group button:active,
  .button-group button:focus {
    color: #fff;
    background: #ff8c2e;
    border-color: none !important;
  }

  .button-group #reset-btn,.button-group #contact-us-btn {
    border-color: none;
    border-radius: 4px !important;
    flex-direction: row !important;
    text-align: center;
    align-self: center !important
  }

  #reset-btn {
    color: #3F3F3F;
    background-color: #fff;
    @media only screen and (max-width: 769px) {
      margin-top: 60px;
    }
  }
  .button-group #reset-btn::hover {
    background-color: lightgrey;
    border-color: #4b0069;
  }

  .ant-select .ant-select-selector,
  .ant-select .ant-select-selector::focus,
  .ant-select,
  .ant-select::focus {
    border: none;
    padding: 0px !important;
    background: #fff;
  }

  .ant-select-dropdown, .ant-form-item-control-input-content {
    background: #ffffff;
    /* Mono/Gray 2 */

    border: 1px solid #dde2e5;
    box-sizing: border-box;
    border-radius: 8px;
  }
  .rc-virtual-holder {
    width: auto;
    max-width: 85%;
  }

  #reset-btn:hover {
    color: #550076;
  }
  @media only screen and (max-width: 769px) {
    #reset-btn {
      margin-top: 60px;
    }
    .button-group {
      height: 175px;
      margin: 50px auto 150px auto;
    }
    .form-row .button-group .ant-col {
      justify-content: space-between
    }
  }
`;
