/* eslint-disable quotes */
import React from 'react';
import { Row, Col } from 'antd';
import { ReactComponent as Data } from '../../images/data.svg';
import { ReactComponent as Fragment } from '../../images/fragment.svg';
import { ReactComponent as Gear } from '../../images/gear.svg';
import { ReactComponent as Scalability } from '../../images/scalability.svg';
import { ReactComponent as Target } from '../../images/target.svg';
import { ReactComponent as Workflow } from '../../images/workflow.svg';
import {
  ContainerStyled,
  CardStyled,
  MetaStyled,
  FeatureTitle,
} from './styles';

function Features() {
  return (
    <>
      <ContainerStyled id="features" fluid>
        <div style={{ marginTop: '50px', paddingBottom: '50px' }}>
          <Row className="d-flex justify-content-center pt-1">
            <Col style={{ marginBottom: '30px' }}>
              <FeatureTitle>Explore Rundis additional benefit</FeatureTitle>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col
              sm={18}
              md={20}
              className="d-lg-flex justify-content-between pb-sm-0 pb-md-5"
            >
              <CardStyled cover={<Gear />}>
                <MetaStyled
                  title="Manage Everything"
                  description="Tracking Devices and People for better management, all in a single event & action. Example of onboarding or terminating an employee."
                />
              </CardStyled>
              <CardStyled cover={<Workflow />}>
                <MetaStyled
                  title="Friendly Workflows"
                  description="Rundis allows you to set up you automation easily within 60 seconds, even without any coding experience. User friendly front-end Interface for anyone."
                />
              </CardStyled>
              <CardStyled cover={<Scalability />}>
                <MetaStyled
                  title="Easy Scalability"
                  description="Rundis allows you to set up you automation easily within 60 seconds, even without any coding experience. User friendly front-end Interface for anyone."
                />
              </CardStyled>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col
              sm={18}
              md={20}
              className="d-lg-flex justify-content-md-center justify-content-lg-between"
              style={{ width: '100%' }}
            >
              <CardStyled cover={<Data />}>
                <MetaStyled
                  title="Data Intelligence"
                  description="Data providers to ingest the data, Collections to categorize and group data sets."
                />
              </CardStyled>
              <CardStyled cover={<Fragment />}>
                <MetaStyled
                  title="Language Agnostic"
                  description="The product is language agnostic allowing for custom actions. Tool integrates the change does that for you. It works similar to what AWS lambda does that for you."
                />
              </CardStyled>
              <CardStyled cover={<Target />}>
                <MetaStyled
                  title="Custom Actions"
                  description="This comes with custom actions, for instance they may have azure functions, that are setup in separate modules and the gateway will trigger that action."
                />
              </CardStyled>
            </Col>
          </Row>
        </div>
      </ContainerStyled>
    </>
  );
}

export default Features;
