import styled, { createGlobalStyle } from 'styled-components';
import { Row, Col } from 'antd';

export const BannerGlobalStyle = createGlobalStyle`
  .easy-use-text {
    font-size: calc(16px + 0.1vw);
    font-style: normal;
    font-weight: normal;

    text-align: center;
    letter-spacing: -0.025em;
    text-transform: capitalize;
    margin-bottom: 20px;
    width: 640px;
    padding: 10px 60px;

    color: #F2F2F2;
    @media only screen and (max-width: 681px) {
      font-size: 14px;
      line-height: 120%;
      margin-bottom: 15px;
      width: 100%;
      padding: unset;
    } 
    @media only screen and (max-height: 736px) {
      font-size: 12px;
      padding: unset;
    }
  }
  .signup-free-btn {
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    text-align: center;
    letter-spacing: -0.02em;
    background-color: #F2F2F2 !important;
    color: #000000 !important;

    height: 54px !important;

     @media only screen and (max-width: 681px) {
       color: #E43A3A !important;
     }
    @media only screen and (max-height: 736px) {
      font-size:12px;
      height: 48px !important;
    }
  }

  .signup-free-btn:hover {
    background-color: #550076 !important;
    border-color: #4b0069 !important;
    color: #F2F2F2 !important;
  }

  .provider-text {
    font-size: calc(14px + 0.1vw);
    line-height: calc(24px + 0.2vw);
    margin-bottom: 20px;
     @media only screen and (max-width: 681px) {
      font-size: 14px;
      line-height: 124.5%;
      /* or 17px */

      text-align: center;
      letter-spacing: -0.02em;

      /* Gray 1 */
      color: #333333;
      }
  }
`;

export const GradientRow = styled(Row)`
  width: 100vw;
  margin: 0;
  background: linear-gradient(71.81deg, #FF8C2E -21.73%, #CB5956 24.45%, #70009C 75.03%);
  @media only screen and (max-width: 681px) {
    background: linear-gradient(359.68deg, #FF8C2E 18.98%, #70009C 100.01%);
   }
 `

export const DashboardRow = styled(Row)`
background: #FEF2E9;
  width: 100vw;
  padding: 0;
  margin: 0;
`;

export const StyledHeader = styled.header`
  height: 60%;
  margin: 5% auto 30% auto;
  @media only screen and (max-width: 1330px) {
    margin: 20% 5% 10% 5%;
  }
  @media only screen and (max-width: 681px) {
    margin: 25% auto 10% auto;
  }
  @media only screen and (max-height: 736px) {
    margin: 0px auto;
  }
`

export const BannerTitle = styled.h1`
  margin: 40% auto 0px auto;
  font-size: calc(68px + 0.1vw);
  font-weight: 400;
  letter-spacing: -0.03em;
  @media only screen and (max-width: 1330px) {
    margin: 40% 0px 0px 0px;
  }
  @media only screen and (max-width: 681px) {
    font-size: 36px;
    margin: 50% auto 0px auto;
  }
  @media only screen and (max-height: 736px) {
    font-size: 32px;
  }
`;

export const DashboardCol = styled(Col)`
  width: 100vw;
  display: flex;
  @media only screen and (max-width: 681px) {
    margin: 50% auto 0px auto;
  }
  @media only screen and (max-height: 736px) {
    margin: 38% auto 0px auto;
    height: 20vh;
  }
`;

export const DashboardImage = styled.img`
  width: 80vw;
  @media only screen and (max-width: 681px) {
    width: 75vw;
  }
`;

export const Container = styled.div`
  margin: '0 0 120px 0';
  @media only screen and (max-width: 681px) {
    margin: '0 0 16px 0';
    width: 100vw;
    padding: 0 !important;
  }
`;

export const ContainerTitle = styled.h2`
  font-weight: 600;
  font-size: calc(32px + 0.2vw);
  line-height: calc(48px + 0.2vw);
  /* or 133% */

  text-align: center;
  letter-spacing: -0.03em;

  color: #000000;
  margin: 100px 0 0 0;
  @media only screen and (max-width: 681px) {
    font-size: 32px;
    letter-spacing: -0.05em;
    margin: 0;
  }
`;

export const ColTitle = styled.h3`
  font-weight: bold;
  font-size: calc(24px + 0.1vw);
  line-height: calc(34px + 0.1vw);
  /* identical to box height, or 142% */

  text-align: center;
  letter-spacing: -0.025em;
  text-transform: capitalize;

  color: #3F3F3F;

  margin-bottom: 15px;
  
  @media only screen and (max-width: 681px) {
    font-size: 18px;
    line-height: 120%;
  }
`;

export const ProviderImage = styled.img`
  width: calc(360px + 0.2vw);
  height: intrinsic;
  @media only screen and (max-width: 681px) {
    display: none;
  }
`;
