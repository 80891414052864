/* eslint-disable quotes */
import React from 'react';
// eslint-disable-next-line object-curly-newline
import { Row, Col } from 'antd';
import arlington from '../../images/arlington.jpg';
import firos from '../../images/firos.jpg';
import riccardo from '../../images/riccardo.jpg';
import {
  TopRow,
  ContentHeader,
  ContentBox,
  Content,
  SignUpButton,
  GridGlobalStyle,
} from './styles';

// eslint-disable-next-line react/prop-types
const GridContent = ({ setModalVisible }) => (
  <>
    <GridGlobalStyle />
    <TopRow className="d-flex justify-content-center align-items-center top-row flex-md-column flex-lg-row">
      <Col
        className="d-flex flex-column align-items-sm-center align-items-md-start"
        xs={{ span: 18, order: 2 }}
        md={{ span: 9, order: 1 }}
        style={{ margin: '0', width: '100vw' }}
      >
        <ContentBox className="d-flex flex-column align-items-sm-center align-items-md-start">
          <ContentHeader
            className="mb-4"
            style={{ width: 'calc(380px+0.1vw)' }}
          >
            Setup is quick & <br />
            easy. No Code <br />
            required
          </ContentHeader>
          <Content className="content-one">
            Get registered and access your dashboard fast within 60 seconds of
            filling out the contact form.
            <br />
            <br />
            Once in the app, adding a new data provider is simple.
            <br />
            <br />
            Add a data provider within minutes of working with the application,
            even with no coding experience.
          </Content>
          <SignUpButton
            className="bg-white text-primary d-flex justify-content-sm-center justify-content-md-start align-self-center align-self-md-start p-sm-2 p-md-0"
            onClick={() => setModalVisible(true)}
          >
            Sign Up for Free
          </SignUpButton>
        </ContentBox>
      </Col>
      <Col
        className="d-flex justify-content-sm-center"
        xs={{ span: 20, order: 1 }}
        md={{ span: 9, order: 2 }}
      >
        <img
          src={arlington}
          className="border-0 mb-5 fluid grid-img"
          alt="two women look at a computer screen"
          style={{ width: '100%' }}
        />
      </Col>
    </TopRow>
    <Row
      className="d-flex justify-content-center align-items-center flex-md-column flex-lg-row"
      style={{ marginBottom: '150px' }}
    >
      <Col
        className="d-flex justify-content-md-end justify-content-sm-center"
        xs={18}
        md={9}
      >
        <img
          src={firos}
          className="border-0 mb-5 fluid grid-img"
          alt="one man staring into a computer screen"
          style={{ width: '100%' }}
        />
      </Col>
      <Col
        className="d-flex flex-column align-items-sm-center align-items-md-start  ml-lg-5 pl-lg-5"
        xs={18}
        md={9}
      >
        <ContentBox className="d-flex flex-column align-items-sm-center align-items-md-start">
          <ContentHeader
            className="mb-4"
            style={{ width: 'calc(500px+0.1vw)' }}
          >
            Integrate Tools & <br />
            People, All in One-place
          </ContentHeader>
          <Content className="content-two">
            Connect your app and get insights & actions based on the data of
            your equipment or employees.
            <br />
            <br />
            Easy to connect all your event-driven processes to get up and
            running sooner without having to wait months for a custom solution.
            <br />
            <br />
            Track all sorts of data such as Compliance, inventory, remote
            working, locales, versioning of devices
          </Content>
          <SignUpButton
            className="bg-white text-primary d-flex justify-content-sm-center justify-content-md-start align-self-center align-self-md-start p-sm-2 p-md-0"
            onClick={() => setModalVisible(true)}
          >
            Sign Up for Free
          </SignUpButton>
        </ContentBox>
      </Col>
    </Row>
    <Row
      className="d-flex justify-content-center align-items-center flex-md-column flex-lg-row"
      style={{ marginBottom: '150px' }}
    >
      <Col
        className="d-flex flex-column align-items-sm-center align-items-md-start"
        xs={{ span: 18, order: 4 }}
        md={{ span: 9, order: 3 }}
      >
        <ContentBox className="d-flex flex-column align-items-sm-center align-items-md-start">
          <ContentHeader
            className="mb-4"
            style={{ width: 'calc(420px+0.1vw)' }}
          >
            Compelling Artificial <br />
            Intelligence tool
          </ContentHeader>
          <Content className="content-three">
            Rundis will disrupt your day-to-day monotony and uncover the useful
            information that you need to know about your business & help your
            organization understand its information systems.
            <br />
            <br />
            None of the automation competitors has the experience and machine
            learning tools to suggest actionable steps like Rundis.
            <br />
            <br />
            The Rundis revolution is disruptive to the status quo.
          </Content>
          <SignUpButton
            className="bg-white text-primary d-flex justify-content-sm-center justify-content-md-start align-self-center align-self-md-start p-sm-2 p-md-0"
            onClick={() => setModalVisible(true)}
          >
            Sign Up for Free
          </SignUpButton>
        </ContentBox>
      </Col>
      <Col
        className="d-flex justify-content-sm-center"
        xs={{ span: 18, order: 3 }}
        md={{ span: 9, order: 4 }}
      >
        <img
          src={riccardo}
          className="border-0 mb-5 fluid grid-img"
          alt="man in a suit holding a lit light bulb"
          style={{ width: '100%' }}
        />
      </Col>
    </Row>
  </>
);

export default GridContent;
