/* eslint-disable quotes */
import React from 'react';
import { Row, Col } from 'antd';
import ContactForm from './ContactForm';
import {
  ContactFormGlobalStyle,
  FormContainer,
  TopRow,
  TopText,
  FormTitle,
  BottomText,
  FormCol,
} from './styles';

const ContactFormWrapper = () => (
  <>
    <ContactFormGlobalStyle />
    <FormContainer className="d-flex flex-column">
      <TopRow>
        <Col
          sm={16}
          md={20}
          className="text-center d-flex flex-column align-items-center"
        >
          <TopText>Get Started</TopText>
          <FormTitle className="text-light text-center">Contact Us</FormTitle>
          <BottomText>
            We’d love to talk how we can work together with you?
          </BottomText>
        </Col>
      </TopRow>
      <Row id="contact-form">
        <FormCol sm={23} md={14} className="text-center mx-auto">
          <ContactForm />
        </FormCol>
      </Row>
    </FormContainer>
  </>
);

export default ContactFormWrapper;
