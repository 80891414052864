/* eslint-disable quotes */
import React from 'react';
// eslint-disable-next-line object-curly-newline
import { Row, Col, Button, notification } from 'antd';
// import { Link } from 'react-router-dom';
import {
  PricingTitle,
  Card,
  CardHeader,
  CardTitle,
  CardSubTitle,
  CardBody,
  Price,
  Link,
  DividerStyled,
} from './styles';

// eslint-disable-next-line react/prop-types
function Pricing({ setModalVisible }) {
  const openNotification = () => {
    notification.open({
      message: 'Thanks!',
      description:
        'Our plans are yet to be determined at the moment. Please complete the contact form below.',
      onClick: () => {
        console.log('Notification Clicked!');
      },
    });
  };

  return (
    <>
      <Row className="d-flex justify-content-center">
        <Col sm={24}>
          <PricingTitle>Plans & Pricing</PricingTitle>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col
          className="card-deck mb-3 text-center  d-sm-flex flex-sm-column d-md-flex flex-md-row flex-sm-wrap d-md-flex flex-md-row align-items-sm-center justify-content-md-center"
          // style={{ minWidth: "40%", maxWidth: "60%" }}
          sm={24}
        >
          <Card className="card mb-5 box-shadow mx-md-3 mx-lg-5">
            <CardHeader className="card-header border-0">
              <CardTitle className="my-0 font-weight-normal">
                Individual
              </CardTitle>
              <CardSubTitle>One User</CardSubTitle>
            </CardHeader>
            <DividerStyled />
            <CardBody className="card-body">
              <Price className="card-title pricing-card-title">$0</Price>
              {/* <Text
                className="d-none"
                style={{
                  fontSize: '14px',
                  lineHeight: '142%',
                  letterSpacing: '-0.02em',
                  
                  
                  color: '#495057',
                }}
                >
                Per Month
              </Text> */}
              <Button
                onClick={() => setModalVisible(true)}
                type="primary"
                className="btn-primary mt-5"
                size="large"
                block
                style={{
                  width: 'calc(215px + 0.2vw)',
                  height: 'calc(48px + 0.2vh)',
                  fontWeight: '600',
                  fontSize: 'calc(14px + 0.2vw)',
                  lineHeight: 'calc(19px + 0.2vw)',
                }}
              >
                Signup Now
              </Button>
            </CardBody>
          </Card>
          <Card className="card mb-5 box-shadow mx-md-3  mx-lg-5">
            <CardHeader className="card-header border-0">
              <CardTitle className="my-0 font-weight-normal">Team</CardTitle>
              <CardSubTitle>Two Users to 1,000 users</CardSubTitle>
            </CardHeader>
            <DividerStyled />
            <CardBody className="card-body">
              <Price className="card-title pricing-card-title">TBD</Price>
              {/* <Text
                className="mb-5"
                style={{
                  fontSize: '14px',
                  lineHeight: '142%',
                  letterSpacing: '-0.02em',

                  color: '#495057',
                  margin: '20px',
                }}
              >
                Per Month
              </Text> */}
              {/* <Link
                href="#contact-form"
                className=" d-flex align-items-center justify-content-center"
              > */}

              <Button
                type="primary"
                className="btn-primary mt-5"
                size="large"
                onClick={openNotification}
                block
                style={{
                  width: 'calc(215px + 0.2vw)',
                  height: 'calc(48px + 0.2vh)',
                  fontWeight: '600',
                  fontSize: 'calc(14px + 0.2vw)',
                  lineHeight: 'calc(19px + 0.2vw)',
                }}
              >
                Get started
              </Button>
              {/* </Link> */}
            </CardBody>
          </Card>
          <Card className="card mb-5 box-shadow mx-md-3 mx-lg-5">
            <CardHeader className="card-header border-0">
              <CardTitle className="my-0 font-weight-normal">
                Enterprise
              </CardTitle>
              <CardSubTitle>Above 1,000 Users </CardSubTitle>
            </CardHeader>
            <DividerStyled />
            <CardBody className="card-body">
              <Price className="card-title pricing-card-title">TBD</Price>
              {/* <Text
                className="mb-5"
                style={{
                  fontSize: '14px',
                  lineHeight: '142%',
                  letterSpacing: '-0.02em',

                  color: '#2b2d30',
                  margin: '20px',
                }}
              >
                Per Month
              </Text> */}
              <Link
                to="#contact-form"
                className=" d-flex align-items-center justify-content-center"
              >
                <Button
                  type="primary"
                  className="btn-primary mt-5 d-flex align-items-center justify-content-center"
                  size="large"
                  block
                  onClick={openNotification}
                  style={{
                    width: 'calc(215px + 0.2vw)',
                    height: 'calc(48px + 0.2vh)',
                    fontWeight: '600',
                    fontSize: 'calc(14px + 0.2vw)',
                    lineHeight: 'calc(19px + 0.2vw)',
                  }}
                >
                  Contact us
                </Button>
              </Link>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Pricing;
