import React from 'react';
import { Row, Col, Typography } from 'antd';
import provider1 from '../../images/provider1.png';
import provider2 from '../../images/provider2.png';
import { Container, ContainerTitle, ProviderImage, ColTitle } from './styles';

const { Text } = Typography;

const ProviderContainer = () => (
  <Container className="d-flex flex-column">
    <Row className="d-flex justify-content-center">
      <Col xs={18} md={7}>
        <ContainerTitle className="mb-5 text-center mt-sm-5">
          Made for your Business & Agile Team
        </ContainerTitle>
      </Col>
    </Row>
    <Row className="mb-2 d-flex flex-row justify-content-around">
      <Col md={8} className="d-md-flex justify-content-center">
        {/* <Col className="d-md-flex justify-content-center"> */}
        <ProviderImage
          src={provider1}
          alt="Code editor with new hire information"
          className="mb-5 border-0"
          fluid
        />
      </Col>
      <Col md={8} className="d-md-flex justify-content-center">
        <ProviderImage
          src={provider2}
          alt="User interface with new hire data providers listed"
          className="mb-5 border-0"
          preview="false"
          fluid
        />
      </Col>
    </Row>
    <Row
      className="d-flex justify-content-around"
      style={{ marginBottom: '50px' }}
    >
      <Col
        xs={18}
        sm={20}
        md={7}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <ColTitle className="text-center">
          Automation Software as a Service
        </ColTitle>
        <Text className="provider-text w-70">
          Get registered and access your dashboard fast within 60 seconds of
          filling out the contact form.
        </Text>
      </Col>
      <Col
        xs={18}
        sm={20}
        md={7}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <ColTitle className="text-center">Business Intelligence Tool</ColTitle>
        <Text className="provider-text w-70">
          Get registered and access your dashboard fast within 60 seconds of
          filling out the contact form.
        </Text>
      </Col>
    </Row>
  </Container>
);

export default ProviderContainer;
