import { Row, Button } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';

export const GridGlobalStyle = createGlobalStyle`
  .grid-img {
    width: calc(600px + 1vw);
    border-radius: 6px;
     @media only screen and (max-width: 681px) {
    width: calc(300px - 1vw);
  } 
  } 
`;
  

export const TopRow = styled(Row)`
  margin: 150px 0;
  width: 100vw;

  @media only screen and (max-width: 681px) {
    margin: 3rem 0 !important;
    width: 100vw;
    margin: 0;
  }
`;

export const ContentHeader = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: calc(44px + 0.1vw);
  line-height: calc(110% + 0.2vw);
  /* or 53px */

  letter-spacing: -0.05em;

  /* Black */

  color: #3F3F3F;

  @media only screen and (max-width: 681px) {
    width: unset;
    font-weight: bold;
    font-size: 32px;

    text-align: center;
  }
`;

export const ContentBox = styled.div`
  max-width: calc(500px+0.1vw);
`;

export const Content = styled.p`
  font-size: calc(14px + 0.1vw);
  line-height: calc(26px + 0.2vw);
  /* or 144% */
  letter-spacing: -0.02em;

  /* Black */

  color: #3F3F3F;
  
  &.content-one {
    width: calc(482px+0.1vw);
    padding-right: 140px;
  }
  &.content-two {
    width: calc(500px+0.1vw);
    padding-right: 110px;
  }
  &.content-three {
    width: calc(477px+0.1vw);
    padding-right: 140px;
  }
  @media only screen and (max-width: 990px) {
    &.content-one, &.content-two, &.content-three{
      width: unset;
      padding-right: unset;
    }
  }

  @media only screen and (max-width: 681px) {
    font-size: 14px;
    line-height: 142%;
    /* or 20px */
    
    text-align: center;
    letter-spacing: -0.02em;

  }
  `;
export const SignUpButton = styled(Button)`
  border: 0px;
  height: 25px;
  width: 200px;
  font-size: calc(14px + 0.1vw);
  line-height: calc(124.5% + 0.2vw);
  /* identical to box height, or 20px */

  margin-top: 43px;

  text-align: center;
  letter-spacing: -0.02em;
  text-decoration-line: underline;

  color: #8f1e85;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

    @media only screen and (max-width: 681px) {
      margin-top: 28px;
    }
`;
