import React from 'react';
import { Link } from './styles';

const NavLink = ({ href, children }) => {
  return (
    <Link href={href} className="nav-link pl-sm-3 ml-md-4 my-lg-0">
      {children}
    </Link>
  );
};

export default NavLink;
