/* eslint-disable no-console */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
const axios = require('axios');
const { message } = require('antd');

let tenantIdUrl = null;

const setTenantId = newTenant => {
  tenantIdUrl = newTenant;
};

const navigateToLogin = url => {
  window.location.href = url;
};

const contactSuccess = fullName => {
  message.success({
    content: `Contact form submitted! Thank you ${fullName}`,
    className: 'custom-class',
    style: {
      marginTop: '20vh',
    },
  });
};
  export const signupFormPost = async (data, url, token) => {
    const formPostRequest = {
      method: 'post',
      url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify(data),
    };


    const res = await axios(formPostRequest)
      .then(response => {
        // console.log('response: ', response.data);
        // console.log('response: ', JSON.stringify(response.data));
        // eslint-disable-next-line no-console
        const attributes = response.data?.data?.attributes;
        // console.log(
        //   'tenant: ',
        //   attributes.rundis_tenant_url_c,
        // );
        setTenantId(attributes.rundis_tenant_url_c);
        // console.log(tenantId);
        // eslint-disable-next-line no-unused-expressions
        tenantIdUrl
          ? navigateToLogin(tenantIdUrl)
          : message.error('Error: Tenant ID undefined!');
      })
      .catch(err => {
        const errObj = err.response?.data?.errors;
        console.log(err);
        for (const property in errObj) {
          // render ant d toasts
          message.error(errObj[property]);
        }
      });
    return res;
  };

  export const contactFormPost = (data, url, token) => {
    if(token) console.log('token received');
    const formPostRequest = {
      method: 'post',
      url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify(data),
    };
    
    axios(formPostRequest)
      .then(response => {
        // console.log(response.data);
        const attributes = response.data?.data?.attributes;
        // console.log(attributes);
        contactSuccess(attributes.first_name);

        
      })
      .catch(err => {
        console.log('form post request error...');
        const errObj = err.response.data.errors;
        for (const property in errObj) {
          // render ant d toasts
          message.error(errObj[property]);
        }
      });
  };
