import React from 'react';
import { Form, Input, Button, Select } from 'antd';
import { parseName, login } from '../../utilities/auth';
import { StyledForm } from './styles';
import { contactFormPost } from '../../services/formServices';

const { TextArea } = Input;
const { Item, useForm } = Form;
const { Option } = Select;

const ContactForm = () => {
  const [contactForm] = useForm();
  const formName = 'contactForm';
  const apiUrl = 'https://crm.rundis.io/Api/V8/module';
  const companySizes = [
    '< 1000',
    '> 1000 & < 5000',
    '> 5000 & < 10000',
    '> 10000 & < 20000',
    '> 20000',
  ];

  const onReset = e => {
    e.preventDefault();
    contactForm.resetFields();
  };

  const handleSubmit = inputs => {
    const name = parseName(inputs.fullName);
    // eslint-disable-next-line
    const data = {
      data: {
        type: 'Contact',
        attributes: {
          ...name,
          sign_up_c: false,
          lead_source: 'Web Site',
          email1: inputs.email,
          email_addresses_primary: inputs.email,
          company_size_c: inputs.size,
          website_url_c: inputs.website,
          notes: inputs.note,
        },
      },
    };

    console.log('form data success');

    const sendForm = async () => {
      console.log('sending form');
      const auth = await login();
      console.log('login complete and post contact form now');
      contactFormPost(data, apiUrl, auth.access_token);
    };

    sendForm();

    contactForm.resetFields();
  };
  return (
    <StyledForm
      name={formName}
      method="post"
      onFinish={handleSubmit}
      form={contactForm}
      layout="horizontal"
      size="large"
      initialValues={{
        remember: true,
      }}
    >
      <div className="form-row d-md-flex flex-md-row">
        <Item
          className="name-group col-md-6 form-group text-left left"
          label="Your Name"
          rules={[
            {
              required: true,
            },
          ]}
          name="fullName"
        >
          <Input
            className="form-control"
            placeholder="First & Last Name"
            required
          />
        </Item>
        <Item
          className="email-group col-md-6 form-group text-left right"
          label="Your Email"
          rules={[
            {
              pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              required: true,
            },
          ]}
          name="email"
        >
          <Input
            className="form-control"
            placeholder="Enter your email"
            type="email"
          />
        </Item>
      </div>
      <div className="form-row d-md-flex flex-md-row">
        <Item
          className="size-group col-md-6 form-group text-left left"
          label="Company Size"
          htmlFor="size"
          name="size"
        >
          <Select
            className="form-control text-left"
            id="size"
            placeholder="Company Size"
            allowClear
          >
            {companySizes.map((size, id) => (
              <Option key={id} className="bg-white text-left" value={size}>
                {size}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          className="website-group col-md-6 form-group text-left right"
          label="Website (URL)"
          name="website"
          rules={[
            {
              pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9-]+)*$/,
            },
          ]}
        >
          <Input
            className="form-control"
            placeholder="Company Website"
            type="url"
          />
        </Item>
      </div>
      <div className="form-row note-textarea">
        <Item
          className="note-group col-md-12 form-group text-left"
          label="Tell us about it in detail?"
          name="note"
        >
          <TextArea
            className="form-control "
            rows="5"
            placeholder="Your Message"
          />
        </Item>
      </div>
      <div className="form-row">
        <Item className="button-group d-sm-flex flex-sm-column justify-content-sm-between">
          <Button id="contact-us-btn" htmlType="submit">
            Contact Me
          </Button>
          <Button id="reset-btn" htmlType="button" onClick={onReset}>
            Reset
          </Button>
        </Item>
      </div>
    </StyledForm>
  );
};

export default ContactForm;
