/* eslint-disable quotes */
import React from 'react';
// eslint-disable-next-line object-curly-newline
import { Row, Col } from 'antd';
import CliCarousel from './CliCarousel';
import Pricing from './Pricing';
import { CliTitle, CarouselGlobalStyle } from './styles';

// eslint-disable-next-line react/prop-types
function CliAndPricing({ setModalVisible }) {
  return (
    <>
      <CarouselGlobalStyle />
      <div id="cli" style={{ marginTop: '75px', marginBottom: '75px' }}>
        <Row className="d-flex justify-content-center">
          <Col md>
            <CliTitle style={{ marginBottom: '50px' }} strong>
              Rundis CLI in Action
            </CliTitle>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col sm={22} md={17}>
            <CliCarousel />
          </Col>
        </Row>
      </div>
      <Row id="pricing" style={{ margin: '75px auto' }}>
        <Col sm={23} md={20} className="mx-auto">
          <Pricing setModalVisible={setModalVisible} />
        </Col>
      </Row>
    </>
  );
}

export default CliAndPricing;
