/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import SignUpForm from '../SignUpForm';
import NavLink from './NavLink';
import SvgIcon from './SvgIcon';
import {
  HeaderGlobalStyle,
  Navbar,
  Brand,
  Nav,
  NavItem,
  Link,
  Collapse,
  Toggle,
  LoginButton,
  SignUpButton,
} from './styles';

const RundisHeader = ({ modalVisible, setModalVisible }) => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const toggler = document.getElementsByClassName('navbar-toggler');

  useEffect(() => {
    // listener to change hamburger to X on mobile
    if (toggler !== 'undefined') {
      toggler[0].addEventListener('click', () =>
        setIsNavCollapsed(!isNavCollapsed),
      );
    }
  }, [isNavCollapsed, toggler]);

  useEffect(() => {
    // click a nav link and close mobile nav
    // yes using jQuery
    $(function eventListenerCollapseTogglerOnClick() {
      $('.nav-link').on('click', function () {
        if ($('.navbar-toggler').css('display') !== 'none') {
          $('.navbar-toggler').trigger('click');
        }
      });
    });
  }, []);

  return (
    <>
      <HeaderGlobalStyle />
      <Navbar className="shadow-md navbar navbar-expand-md bg-white navbar-light navbar-default navbar-fixed-top sticky-top">
        <Brand href="#home" className="navbar-brand">
          <h1 className="logo bg-white d-flex align-NavItems-baseline">
            <SvgIcon id="svg-icon" />
          </h1>
        </Brand>
        <Toggle
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="#navbarSupportedContent"
          aria-expanded={true}
          aria-label="Toggle navigation"
        >
          {isNavCollapsed ? (
            <span className="navbar-toggler-icon" />
          ) : (
            <span className="close" aria-hidden="true">
              &times;
            </span>
          )}
        </Toggle>
        <Collapse
          id="navbarSupportedContent"
          className="collapse navbar-collapse bg-white mh-lg-100 gray-font"
        >
          <Nav className="navbar-nav spa-links">
            <NavItem className="nav-item ml-sm-0 ml-md-4">
              <NavLink href="#features">Features</NavLink>
            </NavItem>
            <NavItem className="nav-item ml-sm-0 ml-md-4">
              <NavLink href="#cli">CLI</NavLink>
            </NavItem>
            <NavItem className="nav-item  ml-sm-0 ml-md-4">
              <NavLink href="#pricing">Pricing</NavLink>
            </NavItem>
          </Nav>
          <Nav className="navbar-nav flex-md-row flex-column ml-md-auto d-flex align-NavItems-baseline gray-font">
            <NavItem className="nav-item d-flex align-items-center justify-content-center my-lg-0 mr-md-3">
              <Link
                href="https://crm.rundis.io"
                className="nav-link d-flex justify-content-center login-button-link"
              >
                <LoginButton type="text">Login</LoginButton>
              </Link>
            </NavItem>
            <NavItem className="nav-item pr-0">
              <div className="w-100 d-flex justify-content-center pr-0">
                <SignUpButton
                  id="sign-up-btn-nav-link"
                  className="btn-primary mx-sm-auto nav-link"
                  onClick={() => {
                    setModalVisible(true);
                  }}
                >
                  Sign Up for Free
                </SignUpButton>
              </div>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
      <SignUpForm
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
    </>
  );
};

export default RundisHeader;
