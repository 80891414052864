/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import React from 'react';
import { Row, Col } from 'antd';

function Main({ children }) {
  return (
    <>
      <Row style={{ background: '100vh', position: 'relative' }}>
        <Col
          sm={24}
          style={{
            overflowX: 'hidden',
            position: 'sticky',
            top: 0,
            zIndex: 200,
          }}
        >
          {children}
        </Col>
      </Row>
    </>
  );
}

export default Main;
