import styled, { createGlobalStyle } from 'styled-components';

const SignUpModalGlobalStyle = createGlobalStyle`

.login-btn {
  font-weight: 500;
  font-size: calc(16px + 0.1vw);
  line-height: calc(150% + 0.1vw);
  /* identical to box height, or 24px */
  text-align: center;
  letter-spacing: -0.045em;
  /* Black */
  color: #3F3F3F;
}

#sign-up-modal {
  background: linear-gradient(359.68deg, rgba(255, 140, 46, 0.05) 18.98%, rgba(112, 0, 156, 0.05) 100.01%);
  /* opacity: 0.1; */
  border-radius: 8px;
  width: 550px !important;
  height: 800px !important;
  z-index: 1002;
}

.ant-modal-header {
  border: none;
  padding-top: 50px;
}
.ant-modal-header * {
  @media only screen and (max-width: 681px) {
    padding: 0px;
  }
}
.ant-modal-body {
  padding-bottom: 50px;
}

.have-an-account{
  font-weight: 500;
  font-size: calc(12px + 0.1vw);
  line-height: calc(150% + 0.1vw);
  /* identical to box height, or 24px */
  display: flex;
  align-items: center;
  text-align: left;
  letter-spacing: -0.045em;
  /* Gray 3 */
  color: #828282;
}

#login-link-sign-up-modal {
  color: #550076;
}

#orange-title-sign-up-modal {
  font-weight: 500;
  font-size: calc(18px + 0.1vw);
  line-height: calc(24px + 0.1vw);
  /* identical to box height, or 75% */
  letter-spacing: -0.02em;
   color: #F27243;
  @media only screen and (max-width: 681px) {
    padding: 0px;
  }
 }

.signup-modal-paragraph{
  /* Paragraph */
  font-size: calc(12px + 0.1vw);
  line-height: calc(24px + 0.1vw);
  /* or 150% */
  display: flex;
  letter-spacing: -0.02em;
  /* Gray 1 */
  color: #333333;
  @media only screen and (max-width: 681px) {
    padding: 0px !important;
  }
}

.sign-up-modal .ant-form-item-label label {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: calc(12px + 0.1vw);
  line-height: calc(19px + 0.1vw);
  /* identical to box height */
  /* Gray 2 */
  color: #4F4F4F;
}

.sign-up-modal .ant-input-affix-wrapper {
  width: 90%;
      @media only screen and (max-width: 681px) {
        width:100%;
      }
}

.sign-up-modal .ant-input-affix-wrapper .ant-input-prefix {
  margin-left: 20px;
  margin-right: 10px;
}

.sign-up-modal .ant-input-affix-wrapper .ant-input {
  height: 40px;
  min-width: 90%;
  max-width: 100%;
  border-radius: 8px;
}
.sign-up-modal .ant-input {
  font-weight: 500;
  font-size: calc(12px + 0.1vw);
  line-height: calc(20px + 0.1vw);
  /* identical to box height, or 125% */
  display: flex;
  align-items: center;
  /* Gray 3 */
  color: #828282;
}

#sign-up-modal .ant-modal-footer{
  border: none;
}

#sign-up-modal .ant-btn-primary, .sign-up-modal .ant-btn {
  height: 54px;
  width: 211px;
  border-radius: 8px;
  padding: 15px, 60px, 15px, 60px;
  color: #fff;
  border-color: #4b0069;
  /* Paragraph */
  font-family: Inter;
  font-size: calc(14px + 0.1vw); 
  line-height: calc(24px + 0.1vw);
  /* identical to box height, or 150% */
  text-align: center;
  letter-spacing: -0.02em;
}

#sign-up-modal .ant-btn-primary:hover {
  background-color: #550076;
  border-color: #4b0069;
  color: #fff;
}

#sign-up-modal .ant-btn-primary:active {
  background-color: #fff;
  border-color: #4b0069;
  color: #550076;
}

#sign-up-modal .ant-btn-primary:focus {
  background-color: #fff;
  border-color: #4b0069;
  color: #550076;
}

.ant-modal-close {
  padding: 20px;
 }

 .ant-modal-close span svg {
   width: 20px;
   height: 20px;
 }

   .sign-up-submit {
     height: 54px;
     width: 211px;
   }
   .sign-up-submit:hover, .sign-up-submit:focus {
      background-color: #550076 !important;
   }
   .button-wrapper {
     margin-top: 40px;
   }
   .ant-modal {
     width: 550 !important;
     height: 800 !important;

   }
`;

export const Brand = styled.a`
  width: 110px;
  .logo {
    width: 80px;
  }
  `;

export default SignUpModalGlobalStyle;
