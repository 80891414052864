/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
import axios from 'axios';
import qs from 'qs';
import dotEnv from '../dotEnv';

const { CLIENT_ID, CLIENT_SECRET, TOKEN_URL } = dotEnv;

export const parseName = fullname => ({
  first_name: fullname.split(' ')[0],
  last_name: fullname.split(' ')[1],
});

export const getToken = async () => {
  try {
    return axios({
      url: TOKEN_URL,
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: qs.stringify({
        grant_type: 'client_credentials',
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
      }),
      timeout: 3000,
    });
  } catch (error) {
    console.log('auth error: ', error);
  }
};

 export const login = async () => {
   const response = await getToken();
    if (response) {
      if (response.data && response.data.access_token) {
        console.log('auth data success');
        return response.data;
      }
    } else {
      console.log('login auth error');
    }
  };
