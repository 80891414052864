/* eslint-disable consistent-return */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React from 'react';
// import { useHistory } from 'react-router-dom';
import { Form, Input, Typography, Row, Col, Modal, Button } from 'antd';
import { UserOutlined, MailOutlined } from '@ant-design/icons';
import SignUpModalGlobalStyle from './styles';
import ModalTitle from './ModalTitle';
import { parseName, login } from '../../utilities/auth';
import { signupFormPost } from '../../services/formServices';

const { Item, useForm } = Form;
const { Paragraph, Title } = Typography;

// eslint-disable-next-line react/prop-types
const SignUpForm = ({ modalVisible, setModalVisible }) => {
  const [form] = useForm();
  const formName = 'signUpForm';
  const apiUrl = 'https://crm.rundis.io/Api/V8/module';
  // const history = useHistory();

  const handleSubmit = inputs => {
    const name = parseName(inputs.name);
    // eslint-disable-next-line
    const data = {
      data: {
        type: 'Contact',
        attributes: {
          ...name,
          sign_up_c: true,
          lead_source: 'Web Site',
          email1: inputs.email,
          email_addresses_primary: inputs.email,
        },
      },
    };

    console.log('form data success');

    const sendForm = async () => {
      console.log('sending form');
      const auth = await login();
      console.log('login complete and post signup form now');
      signupFormPost(data, apiUrl, auth.access_token);
      setModalVisible(false);
    };

    sendForm();
  };

  return (
    <>
      <SignUpModalGlobalStyle />
      <Modal
        id="sign-up-modal"
        title={<ModalTitle />}
        centered
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        className="sign-up-modal"
        footer=""
        style={{
          width: '550px !important',
          height: '800px',
        }}
      >
        <Row justify="space-around">
          <Col xs={24} sm={22} md={24}>
            <Title id="orange-title-sign-up-modal" className="col-md-10">
              Sign Up for Free
            </Title>
            <Paragraph className="signup-modal-paragraph col-md-12">
              After creating your account, we’ll work you through how to create
              your first workplace with Rundis
            </Paragraph>
          </Col>
        </Row>
        <Row justify="space-around">
          <Col xs={24} sm={22} md={23}>
            <Form
              name={formName}
              method="post"
              onFinish={handleSubmit}
              layout="vertical"
              form={form}
            >
              {/* This is the hidden field that the sugar crm uses. */}
              <Item
                label="Don't fill this out"
                name="sign_up_c"
                className="hidden"
                style={{ display: 'none' }}
              >
                <Input type="hidden" value />
              </Item>

              <Item
                label="Full Name"
                name="name"
                rules={[{ required: true, message: 'Please enter your name.' }]}
              >
                <Input
                  placeholder="Enter your name"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  size="large"
                  style={{ background: '#fff' }}
                />
              </Item>

              <Item
                label="Email Address"
                name="email"
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'Please enter your email.',
                  },
                ]}
              >
                <Input
                  placeholder="Enter you email"
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  size="large"
                  style={{ background: '#fff' }}
                />
              </Item>
              <Item className="button-wrapper">
                <Button
                  className="sign-up-submit"
                  type="primary"
                  htmlType="submit"
                  disabled={false}
                >
                  Get Started
                </Button>
              </Item>
              <Item className="have-an-account">
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                Have an Account?{' '}
                <a href="https://crm.rundis.io" id="login-link-sign-up-modal">
                  Login
                </a>
              </Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default SignUpForm;
